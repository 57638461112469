<script>
import { VxWalkthruStep } from "@/components/vx/VxWalkthru";
import InstallReleaseItem from "./InstallReleaseItem";

import { useNamespace } from "@/mixins/i18n";

export default {
  name: "DowngradeStep",
  components: {
    VxWalkthruStep,
    InstallReleaseItem,
  },
  mixins: [useNamespace("settings.softwareUpdate.downgradeStep")],

  props: {
    step: {
      type: Number,
      required: true,
    },
    releases: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    computedReleases() {
      return this.releases.map((rel) => {
        return {
          ...rel,
          downloadUrl: rel.url.replace(
            "https://s3.amazonaws.com/resaleai",
            "/s3"
          ),
          filename: `resaleai_v${rel.version.replace(".", "_")}.exe`,
        };
      });
    },
  },
};
</script>

<template>
  <VxWalkthruStep :step="step">
    {{ i18n("title") }}
    <template v-if="releases.length" #detail>
      <InstallReleaseItem
        v-for="release in computedReleases"
        :key="release.id"
        :item="release"
      />
    </template>
    <template v-else #detail>
      <p>{{ i18n("noDowngrades") }}</p>
    </template>
  </VxWalkthruStep>
</template>
